<template>
    <div class="common">
        <!--顶部-->
        <el-row type="flex" justify="space-between" align="middle" class="commonTop">
            <el-row type="flex" align="middle" class="name">
                <router-link v-if="houseInfo.is_admin == 1" :to="{ name: '统计概况'}" >
                    <div @click="clearHouse()">织脉数字化商城连锁版后台管理系统<span style="font-size:16px;color:#606266;margin-left:10px">({{houseInfo.name}})</span></div>
                </router-link>
                <router-link v-if="houseInfo.is_admin == 3 || houseInfo.is_admin == 4" :to="{ name: '门店统计概况'}">
                    织脉数字化商城连锁版后台管理系统
                    <span v-if="houseInfo.warehouse" style="font-size:16px;color:#606266;margin-left:10px">({{houseInfo.warehouse.name}})</span>
                </router-link>
            </el-row>
            <el-row type="flex" justify="space-between" align="middle" class="nav">
                <el-dropdown trigger="click" class="msg">
                    <span class="el-dropdown-link">
                        <i class="el-icon-s-help"></i>
                        <span style="margin-left:5px">公众号</span>
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <img :src="require('../assets/gzh.jpg')" style="width: 170px;padding: 10px;box-sizing: border-box;">
                    </el-dropdown-menu>
                </el-dropdown>
               <el-dropdown trigger="click" class="msg" style="cursor: pointer;margin-right:20px">
                    <span class="el-dropdown-link">
                        <i class="el-icon-shopping-bag-1"></i>
                        <span style="margin-left:5px">小程序商城</span>
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <img :src="require('../assets/codes.jpg')" style="width: 170px;padding: 10px;box-sizing: border-box;">
                    </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown trigger="click" class="msg" style="cursor: pointer;margin-right:20px">
                    <span class="el-dropdown-link">
                        <i class="el-icon-truck"></i>
                        <span style="margin-left:5px">配送APP</span>
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <img :src="require('../assets/app.png')" style="width: 170px;padding: 10px;box-sizing: border-box;">
                    </el-dropdown-menu>
                </el-dropdown>
                <router-link :to="{ name: '门店商品订单',query:{no_devivery:'1'}}">
                    <div class="msg flex flex-ai-c" style="cursor: pointer;">
                        <audio id="myAudio">
                            <source src="../assets/voice1.mp3" type="audio/mpeg">
                            您的浏览器不支持 audio 元素。
                        </audio>
                        
                        <button id="kaiqi" @click="start" style="display:none">开启</button>
                        
                        <p>消息通知</p>
                        <el-badge :value="count == 0 ? '' : count" class="item">
                            <img src="../assets/laba2.png" alt="">
                        </el-badge>
                    </div>
                </router-link>

                <router-link :to="{ name: '库存预警'}">
                    <div class="msg flex flex-ai-c" style="cursor: pointer;">
                        <!-- <audio id="myAudio">
                            <source src="../assets/voice1.mp3" type="audio/mpeg">
                            您的浏览器不支持 audio 元素。
                        </audio>
                        
                        <button id="kaiqi" @click="start" style="display:none">开启</button> -->
                        
                        <p>库存预警</p>
                        <el-badge :value="stock_count == 0 ? '' : stock_count" class="item">
                            <img src="../assets/laba2.png" alt="">
                        </el-badge>
                    </div>
                </router-link>
                
                <el-row class="user">
                    <el-dropdown trigger="hover">
                        <span class="el-dropdown-link flex flex-ai-c">
                            <!-- {{userInfo.is_admin == 1 ? userInfo.user_name : userInfo.username}} -->
                            {{houseInfo.is_admin == 1 ? adminInfo.username : houseInfo.username}}
                            <i class="el-icon-arrow-down" style="margin-left:5px"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
							<el-dropdown-item icon="el-icon-switch-button" v-if="houseInfo.is_admin == 1" @click.native="goAdmin()">返回总平台</el-dropdown-item>
                            <el-dropdown-item icon="el-icon-switch-button" @click.native="logOut()">退出登录</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-row>
            </el-row>
        </el-row>
        <router-view />
    </div>
</template>

<script>
    import axios from 'axios';
    axios.defaults.withCredentials = true; //设置cross跨域 并设置访问权限 允许跨域携带cookie信息
    export default {
        name: 'indexPage',
        data: function () {
            return {
                count:'', 
                stock_count:'',//库存预警
                need_send:'',
              
                timer: null,
                houseInfo: {},
                adminInfo:{}
            }
        },
        mounted: function () {
            this.houseInfo = localStorage.getItem('houseInfo') ? JSON.parse(localStorage.getItem('houseInfo')) : this.houseInfo;
            this.adminInfo = localStorage.getItem('adminInfo') ? JSON.parse(localStorage.getItem('adminInfo')) : this.adminInfo;
            if(this.houseInfo){
                this.getCount();
            }
        },
        methods: {
            //开始播报
            start:function(){
                let vMP3 = document.getElementById("myAudio");
                vMP3.play();
            },
            //暂停播报
            pause: function(){
                let vMP3 = document.getElementById("myAudio");
                vMP3.pause();
            },

            getCount() {
                this.oldCount = this.count;
                // this.oldStockCount = this.stock_count;
                axios.post(this.domain + '/config/warning').then(re => {
                    this.count = re.data.data.order_count;
                    this.stock_count = re.data.data.stock_count;
                    if(re.data.code == '200'){
                        if(re.data.data.order_count > 0 && this.oldCount <= this.count){
                            this.start();
                        }
                        // if(re.data.data.stock_count > 0 && this.oldStockCount < this.stock_count){
                        //     this.speckText('库存不足请及时处理！');
                        // }
                        

                        this.timer = setTimeout(() => {
                            this.getCount();
                        }, 10000);

                    }else if (re.data.code == '201') {
                        this.clearHouse();
                    }
                    
                }).catch(() => { // 请求失败处理
                    setTimeout(() => {
                        this.getCount();
                    }, 5000);
                });
            },
            //语音播报
            speckText(str) {
                var url = "http://tts.baidu.com/text2audio?lan=zh&ie=UTF-8&spd=6&text=" + encodeURI(str);
                new Audio(url).play();
            },
            // speckPause: function(str){
            //    var url = "http://tts.baidu.com/text2audio?lan=zh&ie=UTF-8&spd=6&text=" + encodeURI(str);
            //     new Audio(url).pause();
            // },

            clearHouse:function(){
                clearInterval(this.timer);
                this.timer = null;
                this.pause();
                // this.speckPause();
            },
			goAdmin: function(){
				this.$router.push('/overview')
			},
            logOut: function() {
                var api = "/login/logout";
                this.http.post(api).then(() => {
                    localStorage.clear();
                    this.$router.push("/");
                    this.clearHouse();
                });
            }
        
        }
    }
</script>